import React, { useEffect } from 'react';

const Home = () => {

  useEffect(() => {
    // Function to initialize Webflow
    const initializeWebflow = () => {
      if (window.Webflow) {
        window.Webflow.destroy();  // Ensure any previous Webflow instances are cleaned up
        window.Webflow.ready();    // Trigger Webflow to re-initialize
        window.Webflow.require('ix2').init(); // Initialize Webflow interactions if using IX2
      }
    };

    // Load the webflow.js script
    loadScript('/js/webflow.js', initializeWebflow);

    // Cleanup function to remove the script if the component unmounts
    return () => {
      const script = document.querySelector(`script[src="/js/webflow.js"]`);
      if (script) {
        script.removeEventListener('load', initializeWebflow);
        document.body.removeChild(script);
      }
    };
  });

  const loadScript = (src, onLoad) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = onLoad;
    document.body.appendChild(script);
  };

  return <>
    <div className="section-wrapper">
      <header className="section-home-hero">
        <div className="padding-global">
          <div className="container-medium">
            <div className="padding-bottom padding-xxhuge"></div>
            <div className="home-hero-section-title">
              <div data-w-id="970b6eff-0433-b509-1fb9-dd26cac9593f-A" /* style={{ opacity: "0" }} */
                className="home-hero-header-component">
                <h1 className="heading-style-h1 text-color-white">The Most Advanced<br /> Commission Platform </h1>
                <div className="padding-bottom padding-medium"></div>
                <div className="max-width-large align-center">
                  <p className="text-size-large text-color-white"> We solve the most formidable challenges <br />that elude
                    all other systems.</p>
                </div>
                <div className="padding-bottom padding-xlarge"></div>
                <div className="button-wrapper is-center"><a href="/contact"
                  className="button is-secondary w-button">Schedule Demo</a><a href="features.html"
                    className="button is-tertiary w-button">Find out more</a></div>
              </div>
              <div className="padding-bottom padding-xxlarge"></div>
            </div>
          </div>
        </div>
        <div className="graphic-circle"></div>
      </header>
      <div className="section-home-dashboard">
        <div className="graphic-circle-2"></div>
        <div className="background-bottom-half"></div>
        <div className="padding-global">
          <div className="container-large">
            <div id="w-node-_970b6eff-0433-b509-1fb9-dd26cac9594e-a7d7d998"
              data-w-id="970b6eff-0433-b509-1fb9-dd26cac9594e-B" /* style={{ opacity: "0" }} */ className="home-dashboard-image-wrapper">
              <img src="images/Main-Header.png"
                srcSet="images/Main-Header-p-500.png 500w, images/Main-Header-p-800.png 800w, images/Main-Header-p-1080.png 1080w, images/Main-Header-p-1600.png 1600w, images/Main-Header.png 1934w"
                sizes="(max-width: 479px) 89vw, (max-width: 767px) 93vw, 90vw" width="1440" alt=""
                className="home-dashboard-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="section-why-us">
      <div className="padding-global">
        <div className="container-medium">
          <div className="padding-section-large">
            <div className="why-us-component">
              <div data-w-id="b1553adb-d535-1cee-5963-1b68a84caed9" /* style={{ opacity: "0" }} */ className="max-width-xlarge">
                <div className="why-us-heading-component">
                  <div className="tagline">Why us</div>
                  <div className="padding-bottom padding-small"></div>
                  <h2 className="heading-style-h2">The pillars that set us apart from all others</h2>
                </div>
              </div>
              <div className="padding-bottom padding-huge"></div>
              <div className="why-us-item-wrapper">
                <div className="why-us-item-content">
                  <div className="featured-icon">
                    <div className="icon-1x1-small w-embed"><svg xmlns="http://www.w3.org/2000/svg" width=" 100%"
                      height=" 100%" fill="none" viewBox="0 0 24 25">
                      <path fill="currentColor"
                        d="M12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11A2.98 2.98 0 0 1 22 15c0 1.65-1.35 3-3 3H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95A5.469 5.469 0 0 1 12 6m0-2C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 0 0 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96A7.49 7.49 0 0 0 12 4z">
                      </path>
                    </svg></div>
                  </div>
                  <div className="padding-bottom padding-medium"></div>
                  <div className="text-size-medium text-weight-semibold text-color-neutral-800">Modern Architecture</div>
                  <div className="padding-bottom padding-xsmall"></div>
                  <div className="text-size-small text-color-neutral-600"> Unlike traditional systems that struggle to
                    keep pace with the latest advancements, our software is built on a modern architecture that
                    embraces innovation and scalability. </div>
                </div>
                <div className="why-us-item-content">
                  <div className="featured-icon">
                    <div className="icon-1x1-small w-embed"><svg xmlns="http://www.w3.org/2000/svg" width=" 100%"
                      height=" 100%" fill="none">
                      <path fill="currentColor"
                        d="M11.332 21c-.35 0-.62-.31-.57-.66l.9-6.34h-3.5c-.88 0-.33-.75-.31-.78 1.26-2.23 3.15-5.53 5.65-9.93a.577.577 0 0 1 1.07.37l-.9 6.34h3.51c.4 0 .62.19.4.66-3.29 5.74-5.2 9.09-5.75 10.05-.1.18-.29.29-.5.29Z">
                      </path>
                    </svg></div>
                  </div>
                  <div className="padding-bottom padding-medium"></div>
                  <div className="text-size-medium text-weight-semibold text-color-neutral-800">Real-Time Calculations
                  </div>
                  <div className="padding-bottom padding-xsmall"></div>
                  <div className="text-size-small text-color-neutral-600">In today&#x27;s fast-paced business environment,
                    time is of the essence. With our software, there&#x27;s no need to wait for hours for commission
                    calculations to be completed, every aspect of commission management is executed instantly. </div>
                </div>
                <div className="why-us-item-content">
                  <div className="featured-icon">
                    <div className="icon-1x1-small w-embed"><svg xmlns="http://www.w3.org/2000/svg" width=" 100%"
                      height=" 100%" fill="none" viewBox="0 0 24 25">
                      <path fill="currentColor"
                        d="M10.999 9.747v3.68c0 .35.19.68.49.86l3.12 1.85c.36.21.82.09 1.03-.26.21-.36.1-.82-.26-1.03l-2.87-1.71v-3.4a.77.77 0 0 0-.76-.74c-.41 0-.75.34-.75.75Zm10 .75v-5.29c0-.45-.54-.67-.85-.35l-1.78 1.78a9.01 9.01 0 0 0-7.21-2.6c-4.19.38-7.64 3.75-8.1 7.94a9 9 0 0 0 17.87 2.14c.07-.6-.4-1.12-1-1.12-.5 0-.92.37-.98.86-.43 3.49-3.44 6.19-7.05 6.14-3.71-.05-6.84-3.18-6.9-6.9-.06-3.9 3.11-7.1 7-7.1 1.93 0 3.68.79 4.95 2.05l-2.09 2.09c-.32.32-.1.86.35.86h5.29c.28 0 .5-.22.5-.5Z">
                      </path>
                    </svg></div>
                  </div>
                  <div className="padding-bottom padding-medium"></div>
                  <div className="text-size-medium text-weight-semibold text-color-neutral-800">Time Travel Auditing
                  </div>
                  <div className="padding-bottom padding-xsmall"></div>
                  <div className="text-size-small text-color-neutral-600">While many commission management systems only
                    provide a snapshot of the current network structure, our software goes above and beyond by
                    offering a comprehensive view of the entire historical tree structure at any point in time. </div>
                </div>
                <div className="why-us-item-content">
                  <div className="featured-icon">
                    <div className="icon-1x1-small w-embed"><svg xmlns="http://www.w3.org/2000/svg" width=" 100%"
                      height=" 100%" fill="none">
                      <path fill="currentColor"
                        d="M19.5 12c0-.23-.01-.45-.03-.68l1.86-1.41c.4-.3.51-.86.26-1.3l-1.87-3.23a.987.987 0 0 0-1.25-.42l-2.15.91c-.37-.26-.76-.49-1.17-.68l-.29-2.31c-.06-.5-.49-.88-.99-.88h-3.73c-.51 0-.94.38-1 .88l-.29 2.31c-.41.19-.8.42-1.17.68l-2.15-.91c-.46-.2-1-.02-1.25.42L2.41 8.62c-.25.44-.14.99.26 1.3l1.86 1.41a7.343 7.343 0 0 0 0 1.35l-1.86 1.41c-.4.3-.51.86-.26 1.3l1.87 3.23c.25.44.79.62 1.25.42l2.15-.91c.37.26.76.49 1.17.68l.29 2.31c.06.5.49.88.99.88h3.73c.5 0 .93-.38.99-.88l.29-2.31c.41-.19.8-.42 1.17-.68l2.15.91c.46.2 1 .02 1.25-.42l1.87-3.23c.25-.44.14-.99-.26-1.3l-1.86-1.41c.03-.23.04-.45.04-.68zm-7.46 3.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z">
                      </path>
                    </svg></div>
                  </div>
                  <div className="padding-bottom padding-medium"></div>
                  <div className="text-size-medium text-weight-semibold text-color-neutral-800">Unmatched Configurability
                  </div>
                  <div className="padding-bottom padding-xsmall"></div>
                  <div className="text-size-small text-color-neutral-600">We believe in putting the power in your hands.
                    That&#x27;s why our software offers unmatched configurability and self-service capabilities. Our
                    user interface is completely decoupled from the main system, allowing you to customize it to meet
                    your unique needs and preferences.</div>
                </div>
              </div>
              <div className="padding-bottom padding-huge"></div>
              <div data-w-id="a2cb0a4b-5f13-5776-efad-3b6f9a8d3633" style={{ opacity: "0" }}
                className="max-width-medium text-align-center"></div>
            </div>
            <div className="why-us-cta">
              <div className="why-us-bottom-text-content">
                <h3 className="heading-style-h5 text-weight-semibold">Ready for take-off?</h3>
                <div className="text-size-regular">Get a demo and explore all possibilities. </div>
              </div>
              <div className="padding-bottom padding-large"></div><a href="/contact"
                className="button is-tertiary-black is-small w-button">Schedule Demo</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-home-views background-color-neutral-50">
      <div className="padding-global">
        <div className="container-large">
          <div className="padding-section-large">
            <div className="w-layout-grid home-views-component">
              <div className="views-item">
                <div className="views-content-wrapper">
                  <div className="views-content-left">
                    <div className="views-list">
                      <div className="views-list-item">
                        <div className="views-icon-wrapper">
                          <div id="w-node-_404cfc9b-29ae-ad81-e5c6-b8ede947cb78-a7d7d998"
                            className="icon-1x1-small text-color-neutral-800 w-embed"><svg
                              xmlns="http://www.w3.org/2000/svg" width=" 100%" height=" 100%" fill="none">
                              <path fill="currentColor"
                                d="M11.332 21c-.35 0-.62-.31-.57-.66l.9-6.34h-3.5c-.88 0-.33-.75-.31-.78 1.26-2.23 3.15-5.53 5.65-9.93a.577.577 0 0 1 1.07.37l-.9 6.34h3.51c.4 0 .62.19.4.66-3.29 5.74-5.2 9.09-5.75 10.05-.1.18-.29.29-.5.29Z">
                              </path>
                            </svg></div>
                        </div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-medium text-weight-bold text-color-neutral-800">Instant Commission
                          Engine</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="text-size-small text-color-neutral-600">No more waiting for antiquated systems to
                          run commissions. Commissions are calculated instantly when orders are placed.</p>
                      </div>
                      <div className="padding-bottom padding-small"></div>
                      <div className="views-list-item">
                        <div className="views-icon-wrapper">
                          <div id="w-node-_404cfc9b-29ae-ad81-e5c6-b8ede947cb82-a7d7d998"
                            className="icon-1x1-small text-color-neutral-800 w-embed"><svg
                              xmlns="http://www.w3.org/2000/svg" width=" 100%" height=" 100%" fill="none"
                              viewBox="0 0 24 24">
                              <path fill="currentColor"
                                d="M14.17 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59ZM8 15h8c.55 0 1 .45 1 1s-.45 1-1 1H8c-.55 0-1-.45-1-1s.45-1 1-1Zm0-4h8c.55 0 1 .45 1 1s-.45 1-1 1H8c-.55 0-1-.45-1-1s.45-1 1-1Zm0-4h5c.55 0 1 .45 1 1s-.45 1-1 1H8c-.55 0-1-.45-1-1s.45-1 1-1Z">
                              </path>
                            </svg></div>
                        </div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-medium text-weight-bold text-color-neutral-800">Compensation Plan
                          Templates</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="text-size-small text-color-neutral-600">Binary, Unilevel, Affiliate or Custom</p>
                      </div>
                    </div>
                    <div className="padding-bottom padding-small"></div>
                    <div className="button-group"><a href="/contact" className="button is-small w-button">Get Demo</a><a
                      href="features.html" className="link is-small w-inline-block">
                      <div>All features</div>
                      <div className="icon-1x1-xxsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%"
                        height="100%" fill="none" viewBox="0 0 16 16">
                        <path fill="currentColor"
                          d="m8 2.667-.94.94 3.72 3.727H2.665v1.333h8.113l-3.72 3.727.94.94L13.333 8 7.999 2.667Z">
                        </path>
                      </svg></div>
                    </a></div>
                  </div>
                </div><img src="images/Main-Image.png" loading="lazy"
                  sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 92vw, 57vw" width="1440"
                  alt=""
                  srcSet="images/Main-Image-p-500.png 500w, images/Main-Image-p-800.png 800w, images/Main-Image-p-1080.png 1080w, images/Main-Image-p-1600.png 1600w, images/Main-Image-p-2000.png 2000w, images/Main-Image-p-2600.png 2600w, images/Main-Image.png 2821w"
                  className="views-image" />
              </div>
              <div className="views-item">
                <div className="views-content-wrapper">
                  <div className="views-content-left">
                    <div className="views-list">
                      <div className="views-list-item">
                        <div className="views-icon-wrapper">
                          <div id="w-node-_3bb4a5a4-ce11-f704-4285-3174aed9462a-a7d7d998"
                            className="icon-1x1-small text-color-neutral-800 w-embed"><svg
                              xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40">
                              <path
                                d="M687.26-273.33q25.58 0 43.5-18.5 17.91-18.5 17.91-44.09 0-25.59-17.91-43.5-17.92-17.91-43.5-17.91-25.59 0-44.09 17.91-18.5 17.91-18.5 43.5t18.5 44.09q18.5 18.5 44.09 18.5ZM686.33-150q32.67 0 59.34-14.17 26.66-14.16 44.66-39.5-24.66-13.66-50.3-20.66-25.65-7-53.34-7-27.69 0-53.69 7-26 7-50 20.66 18 25.34 44.33 39.5 26.34 14.17 59 14.17Zm2 70q-79.33 0-135.5-56.5-56.16-56.5-56.16-134.83 0-79.96 56.16-136.31Q608.99-464 688.67-464q79 0 135.5 56.36 56.5 56.35 56.5 136.31 0 78.33-56.5 134.83Q767.67-80 688.33-80ZM480-80q-138.33-33-229.17-157.5Q160-362 160-520v-240.67l320-120 320 120V-505q-25.33-12.33-54.17-19-28.83-6.67-57.16-6.67-107.16 0-182.92 75.95Q430-378.77 430-271.33q0 52.66 20.83 100Q471.67-124 506.33-89q-6.33 3.33-13.16 5.17Q486.33-82 480-80Z">
                              </path>
                            </svg></div>
                        </div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-medium text-weight-bold text-color-neutral-800">Corporate Office</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="text-size-small text-color-neutral-600">Designed for managing data, generating
                          commissions, running reports, creating corporate users, configuring back office, and
                          handling other critical system management functions.</p>
                      </div>
                      <div className="padding-bottom padding-small"></div>
                      <div className="views-list-item">
                        <div className="views-icon-wrapper">
                          <div id="w-node-_3bb4a5a4-ce11-f704-4285-3174aed94634-a7d7d998"
                            className="icon-1x1-small text-color-neutral-800 w-embed"><svg
                              xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 -960 960 960" width="40">
                              <path
                                d="M120-632h720v-168H120v168Zm0 236h720v-168H120v168Zm0 236h720v-168H120v168Zm55.33-522.67v-66.66H242v66.66h-66.67Zm0 236v-66.66H242v66.66h-66.67Zm0 236v-66.66H242v66.66h-66.67Z">
                              </path>
                            </svg></div>
                        </div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-medium text-weight-bold text-color-neutral-800">Data Management</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="text-size-small text-color-neutral-600">Manage details of distributors or customers,
                          including personal information, sales performance, commission earnings, and rank
                          advancements.</p>
                      </div>
                    </div>
                    <div className="padding-bottom padding-small"></div>
                    <div className="button-group"><a href="/contact" className="button is-small w-button">Get Demo</a><a
                      href="features.html" className="link is-small w-inline-block">
                      <div>All features</div>
                      <div className="icon-1x1-xxsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%"
                        height="100%" fill="none" viewBox="0 0 16 16">
                        <path fill="currentColor"
                          d="m8 2.667-.94.94 3.72 3.727H2.665v1.333h8.113l-3.72 3.727.94.94L13.333 8 7.999 2.667Z">
                        </path>
                      </svg></div>
                    </a></div>
                  </div>
                </div><img src="images/Corporate-Office.png" loading="lazy"
                  sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 92vw, 57vw" width="1440"
                  alt=""
                  srcSet="images/Corporate-Office-p-500.png 500w, images/Corporate-Office-p-800.png 800w, images/Corporate-Office-p-1080.png 1080w, images/Corporate-Office-p-1600.png 1600w, images/Corporate-Office-p-2000.png 2000w, images/Corporate-Office-p-2600.png 2600w, images/Corporate-Office.png 2821w"
                  className="views-image" />
              </div>
              <div className="views-item">
                <div className="views-content-wrapper">
                  <div className="views-content-left">
                    <div className="views-list">
                      <div className="views-list-item">
                        <div className="views-icon-wrapper">
                          <div id="w-node-_4015c973-a729-6dc3-6016-8a67342ebb99-a7d7d998"
                            className="icon-1x1-small text-color-neutral-800 w-embed"><svg
                              xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none">
                              <path fill="currentColor"
                                d="M21 6v4.5c0 .55-.45 1-1 1h-9.67c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1H20c.55 0 1 .45 1 1Zm-6.33 12v-4.5c0-.55-.45-1-1-1h-3.33c-.55 0-1 .45-1 1V18c0 .55.45 1 1 1h3.33c.55 0 1-.45 1-1Zm1-4.5V18c0 .55.45 1 1 1H20c.55 0 1-.45 1-1v-4.5c0-.55-.45-1-1-1h-3.33c-.56 0-1 .45-1 1ZM8.33 18V6c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h3.33c.56 0 1-.45 1-1Z">
                              </path>
                            </svg></div>
                        </div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-medium text-weight-bold text-color-neutral-800">Back Office</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="text-size-small text-color-neutral-600">Empower your distributors with self-service
                          capabilities that enable them to track their performance, view commissions, and access
                          valuable training resources.</p>
                      </div>
                      <div className="padding-bottom padding-small"></div>
                      <div className="views-list-item">
                        <div className="views-icon-wrapper">
                          <div id="w-node-_4015c973-a729-6dc3-6016-8a67342ebba3-a7d7d998"
                            className="icon-1x1-small text-color-neutral-800 w-embed"><svg
                              xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none">
                              <path fill="currentColor"
                                d="M12 12.75c1.63 0 3.07.39 4.24.9 1.08.48 1.76 1.56 1.76 2.73V17c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1v-.61c0-1.18.68-2.26 1.76-2.73 1.17-.52 2.61-.91 4.24-.91ZM4 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm1.13 1.1c-.37-.06-.74-.1-1.13-.1-.99 0-1.93.21-2.78.58A2.01 2.01 0 0 0 0 16.43V17c0 .55.45 1 1 1h3.5v-1.61c0-.83.23-1.61.63-2.29ZM20 13c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm4 3.43c0-.81-.48-1.53-1.22-1.85A6.95 6.95 0 0 0 20 14c-.39 0-.76.04-1.13.1.4.68.63 1.46.63 2.29V18H23c.55 0 1-.45 1-1v-.57ZM12 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3Z">
                              </path>
                            </svg></div>
                        </div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-medium text-weight-bold text-color-neutral-800">Team Geneaology</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="text-size-small text-color-neutral-600">Displays the relationships between
                          distributors, showing who recruited whom and how the team has grown over time.</p>
                      </div>
                    </div>
                    <div className="padding-bottom padding-small"></div>
                    <div className="button-group"><a href="/contact" className="button is-small w-button">Get Demo</a><a
                      href="features.html" className="link is-small w-inline-block">
                      <div>All features</div>
                      <div className="icon-1x1-xxsmall w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="100%"
                        height="100%" fill="none" viewBox="0 0 16 16">
                        <path fill="currentColor"
                          d="m8 2.667-.94.94 3.72 3.727H2.665v1.333h8.113l-3.72 3.727.94.94L13.333 8 7.999 2.667Z">
                        </path>
                      </svg></div>
                    </a></div>
                  </div>
                </div><img src="images/Back-Office.png" loading="lazy"
                  sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 92vw, 57vw" width="1440"
                  alt=""
                  srcSet="images/Back-Office-p-500.png 500w, images/Back-Office-p-800.png 800w, images/Back-Office-p-1080.png 1080w, images/Back-Office-p-1600.png 1600w, images/Back-Office-p-2000.png 2000w, images/Back-Office.png 2491w"
                  className="views-image" />
              </div>
              <div className="views-item">
                <div className="views-content-wrapper">
                  <div className="views-content-left">
                    <div className="views-list">
                      <div className="views-list-item">
                        <div className="views-icon-wrapper">
                          <div id="w-node-_91e6b7e1-6117-7a1b-5618-230e76ea64df-a7d7d998"
                            className="icon-1x1-small text-color-neutral-800 w-embed"><svg
                              xmlns="http://www.w3.org/2000/svg" width=" 100%" height=" 100%" fill="none">
                              <path fill="currentColor"
                                d="m4.851 17.764 5.3-5.3 3.25 3.25c.41.41 1.07.39 1.45-.04l7.17-8.07c.35-.39.33-.99-.04-1.37a1 1 0 0 0-1.45.04l-6.39 7.18-3.29-3.29a.996.996 0 0 0-1.41 0l-6.09 6.1a.996.996 0 0 0 0 1.41l.09.09c.39.39 1.03.39 1.41 0Z">
                              </path>
                            </svg></div>
                        </div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-medium text-weight-bold text-color-neutral-800">Reporting &amp;
                          Analytics</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="text-size-small text-color-neutral-600">Gain actionable insights with advanced
                          analytics and reporting functionalities that provide visibility into key metrics and
                          performance indicators.<br /></p>
                      </div>
                      <div className="padding-bottom padding-small"></div>
                      <div className="views-list-item">
                        <div className="views-icon-wrapper">
                          <div id="w-node-_91e6b7e1-6117-7a1b-5618-230e76ea64e9-a7d7d998"
                            className="icon-1x1-small text-color-neutral-800 w-embed"><svg
                              xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none">
                              <path fill="currentColor"
                                d="M4.25 5.61C6.57 8.59 10 13 10 13v5c0 1.1.9 2 2 2s2-.9 2-2v-5s3.43-4.41 5.75-7.39c.51-.66.04-1.61-.8-1.61H5.04c-.83 0-1.3.95-.79 1.61Z">
                              </path>
                            </svg></div>
                        </div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-medium text-weight-bold text-color-neutral-800">Track KPIs</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="text-size-small text-color-neutral-600">Quickly and easily filter reports to display
                          important criteria, such as total sales, enrollments, orders and more.</p>
                      </div>
                    </div>
                    <div className="padding-bottom padding-small"></div>
                    <div className="button-group"><a href="/contact" className="button is-small w-button">Get Demo</a>
                    </div>
                  </div>
                </div><img src="images/2024-05-01_12-44-25.png" loading="lazy"
                  sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 92vw, 57vw" width="1440"
                  alt=""
                  srcSet="images/2024-05-01_12-44-25-p-500.png 500w, images/2024-05-01_12-44-25-p-800.png 800w, images/2024-05-01_12-44-25-p-1080.png 1080w, images/2024-05-01_12-44-25-p-1600.png 1600w, images/2024-05-01_12-44-25-p-2000.png 2000w, images/2024-05-01_12-44-25-p-2600.png 2600w, images/2024-05-01_12-44-25-p-3200.png 3200w, images/2024-05-01_12-44-25.png 5758w"
                  className="views-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-cta-2 is-pricing">
      <div className="padding-global-2">
        <div className="container-large">
          <div className="cta-component-2">
            <div className="cta-content">
              <h2 className="heading-style-h5-2 text-color-white">Demo Pillars today</h2>
              <div className="padding-bottom padding-xsmall"></div>
              <div className="text-size-regular-2 text-color-white">Schedule demo and see the results for yourself.</div>
            </div><a href="/contact" className="button-2 is-tertiary w-button">Schedule Demo</a>
          </div>
        </div>
      </div>
      <div className="cta-background-2"></div>
    </section>
  </>
}

export default Home