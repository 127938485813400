import React from 'react';
import './App.css';
import Home from './pages/home';
import Contact from './pages/contact';
//import DemoRequestModal from './components/demoRequestModal';

function App() {
  if (window.location.pathname == '/contact') { return <Contact /> }
  return <Home />

}

export default App;
