import React, { useState } from 'react';

function SendRequest(method, url, object, onSuccess, onError) {
  var data = JSON.stringify(object)
  SendRawRequest(method, url, "application/json", data, onSuccess, onError);
}

function SendRawRequest(method, url, contentType, data, onSuccess, onError) {
  let xhr = new XMLHttpRequest();

  // Create a state change callback
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4) {
      if (xhr.status >= 200 && xhr.status <= 205) {
        if (xhr.response) {
          onSuccess(JSON.parse(xhr.response));
        }
        else {
          onSuccess(undefined);
        }
      }
      else {
        onError(xhr.responseText, xhr.status);
      }
    }
  };

  // open a connection
  xhr.open(method, url, true);

  // Set the request header i.e. which type of content you are sending
  if (contentType) {
    xhr.setRequestHeader("Content-Type", contentType);
  }

  // Sending data with the request
  xhr.send(data);
}

const Contact = () => {
  const [value, setValue] = useState('');
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setValue(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    setSuccess(false);

    const apiUrl = 'https://api.pillarshub.com/api/v1/DemoRequest';

    setProcessing(true);

    SendRequest("POST", apiUrl, value, () => {
      setSuccess(true);
      setProcessing(false);
    }, () => {
      setError(true);
      setProcessing(false);
    })
  }

  return <>
    {/* <DemoRequestModal show={show} onHide={handHide} /> */}
    <section className="section-contact">
      <div className="padding-global">
        <div className="container-large">
          <div className="padding-section-medium">
            <div className="w-layout-grid contact-component">
              <div data-w-id="ee4fe753-513b-9be4-2357-78184c1568fc" /* style={{ opacity: "0" }}  */ className="contact-content">
                <div className="max-width-large tablet-full">
                  <div className="contact-title-component">
                    <div className="contact-title-wrapper">
                      <div className="tagline text-color-white">Contact PILLARS</div>
                      <div className="padding-bottom padding-small"></div>
                      <h1 className="heading-style-h3 text-color-white">Schedule a Demo</h1>
                      <div className="padding-bottom padding-medium"></div>
                      <p className="text-size-large text-color-white">Wondering if Pillars is the right tool for your
                        business? Meet with our team today to see if there’s a fit.</p>
                    </div>
                    <div className="padding-vertical padding-xxlarge">
                      <div className="divider-bar"></div>
                    </div>
                    <div className="contact-benefit-wrapper">
                      <div className="contact-benefit-item">
                        <div className="icon-1x1-small w-embed"><svg xmlns="http://www.w3.org/2000/svg" width=" 100%"
                          height=" 100%" fill="none" viewBox="0 0 24 24">
                          <path fill="currentColor"
                            d="M21 12.22C21 6.73 16.74 3 12 3c-4.69 0-9 3.65-9 9.28-.6.34-1 .98-1 1.72v2c0 1.1.9 2 2 2 .55 0 1-.45 1-1v-4.81c0-3.83 2.95-7.18 6.78-7.29a7.007 7.007 0 0 1 7.22 7V19h-7c-.55 0-1 .45-1 1s.45 1 1 1h7c1.1 0 2-.9 2-2v-1.22c.59-.31 1-.92 1-1.64v-2.3c0-.7-.41-1.31-1-1.62Z">
                          </path>
                          <path fill="currentColor"
                            d="M9 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM15 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"></path>
                          <path fill="currentColor"
                            d="M18 11.03A6.04 6.04 0 0 0 12.05 6c-3.03 0-6.29 2.51-6.03 6.45a8.075 8.075 0 0 0 4.86-5.89c1.31 2.63 4 4.44 7.12 4.47Z">
                          </path>
                        </svg></div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-regular text-weight-semibold">Support</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="textsizemedium">Call to speak with a real, live human and get all the answers you
                          need today.</p>
                      </div>
                      <div className="contact-benefit-item">
                        <div className="icon-1x1-small w-embed"><svg xmlns="http://www.w3.org/2000/svg" width=" 100%"
                          height=" 100%" fill="none" viewBox="0 0 24 24">
                          <path fill="currentColor"
                            d="M18.109 13.001h-5.06a8.974 8.974 0 0 1-4.94 7.05 3.003 3.003 0 0 1-3.55 2.9c-1.2-.21-2.19-1.2-2.4-2.4a2.998 2.998 0 0 1 2.95-3.55c.95 0 1.78.45 2.33 1.14a6.969 6.969 0 0 0 3.58-5.14h-3.1a2.996 2.996 0 0 1-3.42 1.94c-1.18-.23-2.13-1.2-2.35-2.38a3.013 3.013 0 0 1 2.96-3.56c1.3 0 2.4.84 2.82 2h3.1c-.32-2.23-1.69-4.1-3.59-5.14-.64.8-1.67 1.28-2.81 1.1-1.23-.19-2.26-1.19-2.47-2.42a3.005 3.005 0 0 1 2.95-3.54 2.99 2.99 0 0 1 2.99 2.95 8.974 8.974 0 0 1 4.94 7.05h5.07v-1.79c0-.45.54-.67.85-.35l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79a.5.5 0 0 1-.85-.36v-1.79Z">
                          </path>
                        </svg></div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-regular text-weight-semibold">Trial</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="textsizemedium">Want to download a trial version of Pillars. Contact us today!</p>
                      </div>
                      <div className="contact-benefit-item">
                        <div className="icon-1x1-small w-embed"><svg xmlns="http://www.w3.org/2000/svg" width=" 100%"
                          height=" 100%" fill="none" viewBox="0 0 24 24">
                          <path fill="currentColor"
                            d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3Zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3Zm0 2c-2.33 0-7 1.17-7 3.5V18c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5Zm8 0c-.29 0-.62.02-.97.05.02.01.03.03.04.04 1.14.83 1.93 1.94 1.93 3.41V18c0 .35-.07.69-.18 1H22c.55 0 1-.45 1-1v-1.5c0-2.33-4.67-3.5-7-3.5Z">
                          </path>
                        </svg></div>
                        <div className="padding-bottom padding-small"></div>
                        <div className="text-size-regular text-weight-semibold">Consulting</div>
                        <div className="padding-bottom padding-xsmall"></div>
                        <p className="textsizemedium">We’re eager to help you launch your business by providing you with
                          years of industry experience.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-w-id="29fd728e-3e28-fb68-f8f6-0360663180dd" /* style={{ opacity: "0" }} */ className="contact-content-form">
                <div className="contact-form-block w-form">
                  {!success && <form onSubmit={handleSubmit} className="contact-form">
                    <h2 className="heading-style-h6 text-weight-semibold">Complete form or call (801) 742-1810</h2>
                    <div className="padding-bottom padding-large"></div>
                    <div className="contact-input-group">
                      <div className="contact-item-wrapper"><label htmlFor="First-name" className="contact-field-label">First name
                        *</label>
                        <div className="padding-bottom padding-xsmall"></div><input onChange={handleChange}
                          className="form-input is-small is-last w-input" maxLength="256" name="FirstName"
                          data-name="First name" placeholder="First name" type="text" id="First-name" required={true} />
                      </div>
                      <div className="contact-item-wrapper"><label htmlFor="Last-name-2" className="contact-field-label">Last name
                        *</label>
                        <div className="padding-bottom padding-xsmall"></div><input onChange={handleChange}
                          className="form-input is-small is-last w-input" maxLength="256" name="LastName"
                          data-name="Last name" placeholder="Last name" type="text" id="Last-name-2" required={true} />
                      </div>
                    </div>
                    <div className="padding-bottom padding-medium"></div>
                    <div className="contact-item-wrapper"><label htmlFor="Work-email" className="contact-field-label">Work email
                      *</label>
                      <div className="padding-bottom padding-xsmall"></div><input onChange={handleChange}
                        className="form-input is-small is-last w-input" maxLength="256" name="EmailAddress"
                        data-name="Work email" placeholder="Email address" type="email" id="Work-email"
                        required={true} />
                    </div>
                    <div className="padding-bottom padding-medium"></div>
                    <div className="contact-item-wrapper"><label htmlFor="Company-name" className="contact-field-label">Company name
                      *</label>
                      <div className="padding-bottom padding-xsmall"></div><input onChange={handleChange}
                        className="form-input is-small is-last w-input" maxLength="256" name="CompanyName"
                        data-name="Company name" placeholder="Enter Company" type="text" id="Company-name"
                        required={true} />
                    </div>
                    <div className="padding-bottom padding-medium"></div>
                    <div className="contact-item-wrapper"><label htmlFor="Company-size" className="contact-field-label">Company size
                      *</label>
                      <div className="padding-bottom padding-xsmall"></div><input onChange={handleChange}
                        className="form-input is-small is-last w-input" maxLength="256" name="CompanySize"
                        data-name="Company size" placeholder="Number of employees" type="number" id="Company-size"
                        required={true} />
                    </div>
                    <div className="padding-bottom padding-medium"></div>
                    <div className="contact-item-wrapper"><label htmlFor="Region" className="contact-field-label">Country or
                      region*</label>
                      <div className="padding-bottom padding-xsmall"></div><input onChange={handleChange}
                        className="form-input is-small is-last w-input" maxLength="256" name="Region" data-name="Region"
                        placeholder="Name of country/region" type="text" id="Region" required={true} />
                    </div>
                    <div className="padding-bottom padding-medium"></div>
                    <div className="contact-item-wrapper"><label htmlFor="Notes">Anyting else?</label>
                      <div className="padding-bottom padding-xsmall"></div><input onChange={handleChange}
                        className="form-input is-small is-last w-input" maxLength="256" name="Notes" data-name="Notes"
                        placeholder="How are you looking to use Pillars?" type="text" id="Notes" required={true} />
                    </div>
                    <div className="padding-bottom padding-medium"></div>
                    <div className="text-size-regular">By submitting this form, I acknowledge receipt of the<a
                      href="privacy.html" className="form-link">Privacy Policy.</a></div>
                    <div className="padding-bottom padding-xsmall"></div>
                    <div className="padding-bottom padding-medium"></div>
                    {!processing && <input type="submit" data-wait="Please wait..."
                      className="button w-button" value="Submit" />}
                  </form>}
                  {success && <div className="success-message contact">
                    <div>Thank you! Your submission has been received!</div>
                  </div>}
                  {error && <div className="error-message">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="graphic-circle"></div>
      <div className="graphic-circle-3"></div>
    </section>
  </>
}

export default Contact